body {
  margin: 0;
  font-family: var(--base-family), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

h1,
h2 {
  font-family: var(--header-family);
}
h3,
h4,
h5 {
  font-family: var(--emph-family);
}

a {
  text-decoration: none;
  color: var(--accent-color-2);
}

a:visited {
  color: var(--accent-color-3);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

fieldset {
  -webkit-appearance: none;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  border: none;
  margin: 0;
}

button,
input,
label,
select,
legend {
  font-size: 1em;
  padding: 0.5rem;
}

button {
  -webkit-appearance: none;
}

input {
  -webkit-appearance: none;
  background-color: unset;
  border-width: 0;
  border-style: solid;
  line-height: 1.15;
  margin: 0;
}

input:active {
  border-style: solid;
}
