.Alert {
  display: flex;
  justify-content: center;
  padding-left: 3rem;
}

.Alert__p {
  position: relative;
  min-height: 3rem;
  padding: 1rem 1rem 1rem 5rem;
  display: flex;
  width: 320px;
  flex-flow: column;
  justify-content: center;
}

.Alert__p::before {
  content: '';
  position: absolute;
  height: 3rem;
  width: 3rem;
  background: url(../../assets/svg/alert.svg) no-repeat center;
  background-size: 3rem 3rem;
  left: 1rem;
}

.Button,
.Input__input,
.UnitSetSelect__select,
.Input__label__description,
.UnitSetSelect__label__description {
  font-size: 1.15em;
  line-height: 1.2;
}

.Button {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.5em 0.5em 0.5em;
  color: var(--off-black);
  background-color: var(--accent-color-1);
  transition: background-color 0.5s;
  border: 0;
  border-radius: 0;
  font-family: var(--base-family), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.Button:disabled, .Button:hover:disabled {
  text-decoration: none;
  background-color: var(--dark-gray);
  color: var(--off-white);
  transition: background-color 0.3s, color 0.3s;
}

.Button:hover {
  background-color: var(--accent-color-2);
  text-decoration: underline;
  transition: background-color 0.3s;
}

.Button:visited {
  color: var(--off-black);
}

.App__main {
  margin: 0 1em;
}

a:hover {
  text-decoration: underline;
}

.Form {
  display: flex;
  flex-flow: column;
}

.Fieldset {
  display: grid;
}

.Fieldset legend {
  font-size: 1.25em;
  background: var(--light-gray);
  color: var(--accent-color-3);
  width: 100%;
}

.Fieldset__Ingredient {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
}

.Input__input {
  border: 2px solid var(--dark-gray);
  border-radius: 0;
  background-color: var(--off-white);
}

.Input__input[type='number'] {
  width: 2rem;
}

.Input__input:focus, .UnitSetSelect__select:focus {
  border: 2px solid var(--accent-color-1);
  outline: none;
}

.Input__input:disabled,
.UnitSetSelect__select:disabled {
  background-color: var(--light-gray);
}

.Input__label,
.UnitSetSelect__label,
.Input__label__description,
.UnitSetSelect__label__description {
  border: 0;
}

.Input__label,
.UnitSetSelect__label {
  display: flex;
  flex-flow: column;
}

.UnitSetSelect__select {
  display: block;
  border: 2px solid var(--dark-gray);
}

.UnitSetSelect__label {
  display: flex;
  flex-flow: column;
}

.disabled {
  background-color: var(--light-gray);
  color: var(--dark-gray);
  transition: background-color 0.5s, color 0.5s;
}

.Fieldset__input-row-fix {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}