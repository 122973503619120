/*TODO some light styling on the text would make it polished*/

.RecipeCard {
  border: 1px solid;
  border-color: var(--dark-gray);
}

.RecipeCard__header {
  margin: 1rem 0;
  background-color: var(--light-gray);
}

.RecipeCard__footer {
  background-color: var(--light-gray);
}

.RecipeCard__footer_menu {
  list-style-type: none;
  display: flex;
  background-color: var(--light-gray);
}