.App__Header {
  margin: 1em 1em 0 1em;
  display: grid;
  grid-template-columns: 75px repeat(2, 1fr);
  grid-template-rows: 75px auto auto;
  border-bottom: 1px solid;
}

.Header__logo {
  grid-row: 1 / 2 px;
  grid-column: 1 / 2;
}

header h1 {
  grid-row: 1 / 2;
  grid-column: 2 / 4;
}

.Header__Subtitle {
  grid-row: 2/3;
  grid-column: 1/ 4;
  font-size: 1.25rem;
  margin: .5em 0;
}

.Header {
  grid-row: 3/ 4;
  grid-column: 1/6;
}

.Header__PrimaryNav {
  margin: 1em 0;
  list-style-type: none;
  display: flex;
}

.PrimaryNav__li {
  transform: rotate(-25deg);
  padding: 0.25rem;
  margin: 0.75rem 0;
}

.PrimaryNav__li a {
  text-decoration: none;
}

.PrimaryNav__li a:hover {
  text-decoration: underline;
}

.PrimaryNav__li a:focus {
  text-decoration: underline;
  outline: none;
}

.PrimaryNav__li a::before {
  content: '';
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--accent-color-1);
  transform: rotate(25deg);
  margin-right: .5em;
  transition: background-color .5s, transform .5s;
}

.PrimaryNav__li a:hover::before, .PrimaryNav__li a:focus::before {
  background-color: var(--accent-color-2);
  transform: rotate(65deg);
  transition: background-color .5s, transform .5s;
}

.PrimaryNav__li a:active {
  color: var(--accent-color-1);
  transition: color .33s;
}

@media only screen and (min-width: 768px) {
  .App__header {
    grid-template-rows: 75px 1.5em;
  }

  .Header {
    grid-row: 1/3;
    grid-column: 3/4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
