.DeleteRecipeConfirm__container {
  display: fixed;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

/* Transparent color block background. */
.DeleteRecipeConfirm__container::after {
	content: ''; 
	width: 100%; 
	height: 100%;
  background: var(--accent-color-2);
	opacity: 0.8; 
  position: absolute; 
	top: 0; 
	left: 0;
	z-index: -1;
}


.DeleteRecipeConfirm__content {
  background: var(--accent-color-2);
  margin: 50vh auto;
}