.UserHomeNav__List {
  list-style: none;
  margin: 1em auto;
  background: var(--light-gray);
  padding: 1em;
  display: flex;
  justify-content: center;
}

.UserHomeNav__ListItem a {
  padding: 0.5em 0.5em 0.5em 2em;
}

.UserHomeNav__ListItem a:hover, .UserHomeNav__ListItem a:focus {
  background-color: var(--accent-color-2);
  transition: background-color 0.5s;
  outline: none;
}

.UserHomeNav__ListItem a::before {
  content: '';
  position: absolute;
  height: 1em;
  width: 1em;
  background: url(../../../assets/svg/add.svg) no-repeat center;
  background-size: 1em 1em;
  left: 0.5em;
}

.UserHomeNav__ListItem a::before:active {
  background-color: var(--off-black);
  transition: background-color 0.5s;
  outline: none;
}